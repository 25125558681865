import React from "react";
import { PlayersList } from "../../components/Elements/PlayersList";
import { MessageElement } from "../../components/Elements/MessageElement";
import { motion } from "framer-motion";
import "./styles.scss";

const Rankings = () => {
  const routeVariants = {
    initial: {
      opacity: 0,
      y: "50px",
    },
    final: {
      opacity: 1,
      y: "0px",
      transition: {
        duration: 0.3,
        delay: 0.2,
      },
    },
  };
  return (
    <motion.div
      variants={routeVariants}
      initial="initial"
      animate="final"
      className="rankings--page"
    >
      <div className="element-container-top">
        <MessageElement message="Do you have what it takes to make the top rankings? Leaderboards refresh weekly and monthly so you will always have a chance to compete equally" />
      </div>
      <PlayersList
        withNumber={true}
        usePagination={false}
        useSort={true}
      ></PlayersList>
    </motion.div>
  );
};

export default Rankings;
