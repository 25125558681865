// UserContext.js
import React, { createContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useAuth0 } from '@auth0/auth0-react';
import "react-toastify/dist/ReactToastify.css";

import { getPlayer } from "../services/gamelayer";

// Create the context
const UserContext = createContext();

// Create a context provider component
const UserProvider = ({ children }) => {
  // Define the state to store user information
  const { user } = useAuth0();
  const [account, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true); // Add isLoading state

  // Create a function to update the user information
  const updateUser = (userData, user) => {
    setUser(userData);
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const account = process.env.REACT_APP_ACCOUNT;
        const playerId = user.email;
        const userData = await getPlayer(account, playerId);
        updateUser(userData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching user:", error);
        toast.error(
          `Failed to retrieve user. Please try again. Error: ${error.message}`,
          {
            toastId: "player-fetch-error", // Set a unique toastId for the error message
          }
        );
      }
    };

    fetchUser();
  }, []);

  if (isLoading) {
    return <p>Loading user information...</p>;
  }
  // Provide the state and function as values to the context provider
  return (
    <UserContext.Provider value={{ user, account, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
