import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getEvent } from "../../../services/gamelayer";
import { completeEvent } from "../../../services/gamelayer";
import { UserContext } from "../../../domain/UserProvider";
import "./styles.scss";

/**
   @todo: Convert this into Mission.
**/
export const Event = ({ id }) => {
  const { account } = useContext(UserContext);
  const [event, setEventData] = useState([]);
  const glAccount = process.env.REACT_APP_ACCOUNT;

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const eventData = await getEvent(glAccount, id);
        setEventData(eventData);
      } catch (error) {
        console.error("Error fetching event data:", error);
        toast.error(
          `Failed to retrieve event data. Please try again. Error: ${error.message}`,
          {
            toastId: "event-fetch-error",
          }
        );
      }
    };
    fetchEvent();
  }, []);

  const completeThisEvent = async (id) => {
    try {
      completeEvent(
        glAccount,
        account.player,
        id,
        1
      ).then(
        (response) => {
          if (response === 200) {
            toast.success(
              `You really did it great!
               Your reward is ${event.reward.points} points`
            );
          }
        }
      );

    } catch (error) {
      console.error("Error completing the event:", error);
      toast.error(
        `Failed to complete the event. Please try again. Error: ${error.message}`,
        {
          toastId: "event-complete-error",
        }
      );
    }
  };

  return (
    <button
      className="complete-btn"
      onClick={() => completeThisEvent(id)}
    >
      {event.name}
    </button>
  );
};
