import React from "react";
import { MissionsList } from "../../components/Elements/MissionsList";
import { MessageElement } from "../../components/Elements/MessageElement";
import { motion } from "framer-motion";
import "./styles.scss";

export const Challenges = () => {
  const routeVariants = {
    initial: {
      opacity: 0,
      y: "50px",
    },
    final: {
      opacity: 1,
      y: "0px",
      transition: {
        duration: 0.3,
        delay: 0.2,
      },
    },
  };
  return (
    <motion.div
      variants={routeVariants}
      initial="initial"
      animate="final"
      className="page-content"
    >
      <div className="element-container-top">
        <MessageElement message="Challenges are updated frequently and once they are gone, they are gone. So come back often to make sure you don’t miss any" />
      </div>
      <MissionsList></MissionsList>
    </motion.div>
  );
};
