import React, { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import { Mission } from "../Mission";
import { UserContext } from "../../../domain/UserProvider";
import { motion } from "framer-motion"; // Import motion components
import "react-toastify/dist/ReactToastify.css";
import { getMissions, getPlayerMission } from "../../../services/gamelayer.js";
import "./styles.scss";

const ITEMS_PER_PAGE = 3; // Adjust the number of missions per page as needed

export const MissionsList = ({ usePagination, slimItems = false }) => {
  const glAccount = process.env.REACT_APP_ACCOUNT;
  const { account } = useContext(UserContext);
  const [missions, setMissions] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const sortMissions = async (missionsData) => {
      // Check if missionsData is not an array
      if (!Array.isArray(missionsData)) {
        console.error('missionsData is not an array');
        return [];
      }
    
      // Map each mission to a promise that resolves when availability is determined
      const availabilityPromises = missionsData.map(async (mission) => {
        const missionData = await getPlayerMission(glAccount, account.player, mission.id);
        const available = missionData.progress.state === 'started' || missionData.progress.state === 'available';
        if (available) {
          return mission;
        } else {
          return null;
        }
      });
    
      let data = [];
      // Wait for all promises to resolve
      const availableMissions = await Promise.all(availabilityPromises).then((values) => {
        data = values;
      });
      // Filter out null values (missions that are not available)
      data = data.filter(item => item !== null);
    
      // Sort the available missions by priority
      data.sort((a, b) => a.priority > b.priority ? 1 : -1);
    
      return data;
    }
        
    const fetchMissions = async () => {
      try {
        let missionsData = await getMissions(glAccount);
        missionsData = await sortMissions(missionsData);
        if (usePagination && missionsData) {
          setTotalPages(Math.ceil(missionsData.length / ITEMS_PER_PAGE));
          const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
          const endIndex = startIndex + ITEMS_PER_PAGE;
          const missionsForPage = missionsData.slice(startIndex, endIndex);
          setMissions(missionsForPage);
        } else {
          setMissions([]);
          setTimeout(() => setMissions(missionsData), 0);
        }
      } catch (error) {
        console.error("Error fetching missions:", error);
        toast.error(
          `Failed to retrieve missions. Please try again. Error: ${error.message}`,
          {
            toastId: "mission-fetch-error"
          }
        );
      }
    };

    fetchMissions();
  }, [usePagination, currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <div className="missions-list">
        {missions && missions.map((mission, index) => (
            <Mission key={index}
              missionId={mission.id}
              title={mission.name}
              text={mission.description}
              imageUrl={mission.imgUrl}
              tags={mission.tags}
              category={mission.category}
              points={mission.reward.points}
              slim={slimItems}
              index={index}
            />
        ))}
      </div>
{usePagination && totalPages > 1 && (
        <div className="pagination">
          <motion.button
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
            className="arrow left"
            whileHover={{ scale: 1.1 }}
          />
          {[...Array(totalPages)].map((_, index) => (
            <motion.button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              className={currentPage === index + 1 ? "active dot" : "dot"}
              whileHover={{ scale: 1.1 }}
            />
          ))}
          <motion.button
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
            className="arrow right"
            whileHover={{ scale: 1.1 }}
          />
        </div>
      )}
    </>
  );
};
