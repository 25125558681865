/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Logo3 } from "../../icons/Logo3";
import { Logo4 } from "../../icons/Logo4";
import "./style.css";

export const TypeLogoTextLightWrapper = ({
  type,
  component,
  className,
  icon = <Logo3 className="gamelayer-5" />,
  divClassName,
}) => {
  return (
    <div className={`type-logo-text-light-wrapper ${type} ${className}`}>
      {["logo-text-dark", "logo-text-light"].includes(type) && (
        <>
          {icon}
          <div className={`game-layer ${divClassName}`}>
            {type === "logo-text-light" && <>GameLayer</>}

            {type === "logo-text-dark" && <>Quizzo</>}
          </div>
        </>
      )}

      {type === "logo-default" && <Logo4 className="gamelayer-3" />}
    </div>
  );
};

TypeLogoTextLightWrapper.propTypes = {
  type: PropTypes.oneOf(["logo-default", "logo-text-light", "logo-text-dark"]),
  component: PropTypes.oneOf(["game-layer"]),
};
