/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { Logo3 } from "../../icons/Logo3";
import { TypeLogoTextLightWrapper } from "../TypeLogoTextLightWrapper";
import "./style.css";

export const Footer = ({ className, typeLogoTextLightWrapperIcon = <Logo3 className="gamelayer-instance" /> }) => {
  return (
    <div className={`footer ${className}`}>
      <TypeLogoTextLightWrapper
        className="logo-instance"
        component="game-layer"
        divClassName="logo-2"
        icon={typeLogoTextLightWrapperIcon}
        type="logo-text-light"
      />
      <p className="p">2023 GameLayer Oy - All Rights Reserved</p>
    </div>
  );
};
