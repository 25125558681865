import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getPrizes } from "../services/gamelayer";
import "./styles.css";

const Prizes = () => {
  const [prizes, setPrizes] = useState([]);

  useEffect(() => {
    const fetchPrizes = async () => {
      try {
        const account = process.env.REACT_APP_ACCOUNT;
        const prizesData = await getPrizes(account);
        setPrizes(prizesData);
      } catch (error) {
        console.error("Error fetching prizes:", error);
        toast.error(
          `Failed to retrieve prizes. Please try again. Error: ${error.message}`,
          {
            toastId: "prizes-fetch-error", // Set a unique toastId for the error message
          }
        );
      }
    };

    fetchPrizes();
  }, []);

  return (
    <div className="page-content">
      <h1>Prizes</h1>
      {prizes.map((prize) => (
        <div key={prize.id} className="element-container">
          <img src={prize.imgUrl} alt={prize.name} className="element-image" />
          <div className="element-info">
            <p>{prize.name}</p>
            <p>
              {prize.description === ""
                ? "Description not set"
                : prize.description}
            </p>
            <p>
              Stock: Redeemed: {prize.stock.redeemed}, Available:{" "}
              {prize.stock.available}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Prizes;
