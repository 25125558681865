import React from "react";
import "./styles.scss";

export const Avatar = ({
  type,
  levelIcon,
  imageUrl = "https://images.gamelayer.co/glimages/gl-demo-app/man.png",
  className
}) => {
  return (
    <div className={`avatar type-1-${type} ${className}`}
      style={{ backgroundImage: `url(${imageUrl})` }}
      >
      { levelIcon && (<img className="level-icon" alt="Ellipse" src={levelIcon}/>) }
    </div>
  );
};
