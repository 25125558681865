import React from "react";
import "./style.scss";

export const MessageElement = ({
  message = "Welcome to the employee micro-rewards programme where you can unlock great rewards for keeping up-to-date with company relevant information and tasks",
}) => {
  const reimaOverride = false;
  const hfhOverride = true;

  if (reimaOverride) {
    return (
      <div className="element-message reima">
        <div className="element-message--text-wrapper">
          <img src="/images/assets/MISC/reima80-bg-tile.png" alt="message background" className="background-image"/>
        </div>
        <div className="element-message--image-wrapper">
          <img
            className="element-message--image"
            alt="Image"
            src="/images/assets/MISC/reima80-logo.svg"
          />
        </div>
      </div>
    );
  }

  if (hfhOverride) {
    return (
      <div className="element-message hfh">
        <img src="/images/assets/MISC/Banner_Demo_DD.png" alt="message background" className="background-image"/>
      </div>
    );
  }

  return (
    <div className="element-message">
      <img src="/images/assets/background.png" alt="message background" className="element-message--background-image"/>
      <div className="element-message--text-wrapper">
        <p className="element-message--text-wrapper--text">{message}</p>
      </div>
      <div className="element-message--image-wrapper">
        <img
          className="element-message--image"
          alt="Image"
          src="https://c.animaapp.com/XhrXrzST/img/image-1@2x.png"
        />
      </div>
    </div>
  );
};
