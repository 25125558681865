import React, { useEffect, useState, useContext } from "react";
import { NavLink, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getPrize, redeemPrize } from "../../services/gamelayer";
import { UserContext } from "../../domain/UserProvider";
import { motion } from "framer-motion";
import "./styles.scss";

const PrizePage = () => {
  const { prizeId } = useParams();
  const glAccount = process.env.REACT_APP_ACCOUNT;
  const [prize, setPrize] = useState(null);
  const { account } = useContext(UserContext);

  const routeVariants = {
    initial: {
      opacity: 0,
      y: "50px",
    },
    final: {
      opacity: 1,
      y: "0px",
      transition: {
        duration: 0.3,
        delay: 0.2,
      },
    },
  }

  
  useEffect(() => {
    const fetchPrizeData = async () => {
      const prizeData = await getPrize(glAccount, prizeId);
      return prizeData;
    };

    if (prizeId) {
      const fetchPrize = async () => {
        try {
          const prizeData = await fetchPrizeData();
          setPrize(prizeData);
        } catch (error) {
          console.error("Error fetching prizes:", error);
          toast.error(
            `Failed to retrieve prizes. Please try again. Error: ${error.message}`
          );
        }
      };

      fetchPrize();
    }
  }, [glAccount, prizeId]);

  const showNotification = (message, type) => {
    toast[type](message);
  };

  const handlePrizeRedeem = async () => {
    try {
      const response = await redeemPrize(
        glAccount,
        account.player,
        prize.id
      );

      if (response.code === 1) {
        showNotification("Congrats! Item claimed!", "success");
        // Fetch the updated shop items after a successful prize claim
        const updatedData = await getPrize(glAccount, prizeId);
        setPrize(updatedData);
      } else if (response.code === 2) {
        showNotification("Sorry. You don't have enough credits to claim this item.", "error");
      } else {
        showNotification("Sorry. An error occurred.", "error");
      }
    } catch (error) {
      console.error("Error claiming prize:", error);
      toast.error(
        `Failed to claim the prize. Please try again. Error: ${error.message}`,
        {
          toastId: "prize-claim-error",
        }
      );
    }
  };

  return (
    <motion.div
      variants={routeVariants}
      initial="initial"
      animate="final"
      className="page-content">
      {prize && (
        <div className="prize--page">
          <NavLink
            to="/shop"
            className="back-link"
            >
            <img src="/images/assets/arrow_left.svg" alt="back"></img>
            Shop
          </NavLink>
          <img className="prize--image" src={prize.imgUrl} alt={prize.name}></img>
          {
            prize.tags.length > 0 && (
              <div className="tags">
                { prize.tags.map((tag, index) => (
                  <div className="tag" key={index}>
                    {tag}
                  </div>
                ))}
              </div>
            )
          }
          <div className="prize--content">
            <h1>{prize.name}</h1>
            <div className="prize-stats">
              <p>
                Price: {prize.credits} credits 
              </p>
              <p>
                Initial stock: {prize.stock.count}
              </p>
              <p> 
                Available: {prize.stock.available < 10000 ? prize.stock.available : 'plenty'}
              </p>
              <p> 
                Redeemed: {prize.stock.redeemed} 
              </p>
            </div>
            <div className="prize-info">
              {prize.description}
            </div>
          </div>
          <div className="prize--buttons">
            <button onClick={handlePrizeRedeem}>
              <span>Buy</span>
            </button>
          </div>
        </div>
      )}
      </motion.div>
  );
};


export default PrizePage;
