import React, { useContext } from "react";
import { Logo2 } from "../../icons/Logo2";
import { UserContext } from "../../domain/UserProvider";

import "./styles.css";

const Header = () => {
  const { user } = useContext(UserContext);
  const name = user?.name != null ? user.name : "Pete the Gambler";
  const imgUrl = user?.imgUrl != null ? user.imgUrl : "/images/gl_logo.png";
  return (
    <div className="header">
      <div className="circle">
        <Logo2 />
      </div>
      <div className={`text-wrapper-6`}> GameLayer</div>

      <div className="right-content"></div>
    </div>
  );
};

export default Header;
