import React, { useState, useContext, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { UserContext } from "../../domain/UserProvider";
import { useAuth0 } from '@auth0/auth0-react';
import { Avatar } from "../Elements/Avatar";
import "./styles.scss";

export const Sidebar = () => {
  const location = useLocation();
  const { logout } = useAuth0();
  const { account } = useContext(UserContext);
  const name = account?.name || "";
  const id = account?.player;
  const imgUrl = account?.imgUrl || "/images/gl_logo.png";
  const points = account?.points || 0;
  const credits = account?.credits || 0;

  const menuItems = [
    { name: "Home", path: "/", iconActive: "/images/assets/icons/home-active.svg", iconInactive: "/images/assets/icons/home.svg" },
    { name: "Challenges", path: "/challenges", iconActive: "/images/assets/icons/challenge-active.svg", iconInactive: "/images/assets/icons/challenge.svg" },
    { name: "Rankings", path: "/rankings", iconActive: "/images/assets/icons/ranking-active.svg", iconInactive: "/images/assets/icons/ranking.svg" },
    { name: "Shop", path: "/shop", iconActive: "/images/assets/icons/shop-active.svg", iconInactive: "/images/assets/icons/shop.svg" }
  ];

  const isMenuItemSelected = (menuItemPath) => {
    return location.pathname === menuItemPath;
  };

  const [showSidebar, setShowSidebar] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setShowSidebar(window.innerWidth >= 700);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={`sidebar ${showSidebar ? "show" : "hide"}`}>
      <div className="sidebar-wrapper">
        <NavLink to={"/players/" + id} className="element-user">
          <Avatar
            className="elements-user--avatar"
            imageUrl={imgUrl}
            component="avatar"
            type="default"
          />
          <div>
            <h5 className="element-user--info--name">{name}</h5>
            <p className="element-user--info--points">{points} Points</p>
            <p className="element-user--info--credits">{credits} Credits</p>
          </div>
        </NavLink>
        <div className="menu-items">
          <ul>
            {menuItems.map((menuItem) => (
              <li key={menuItem.name}>
                <NavLink
                  to={menuItem.path}
                  className={`menu-link ${
                    isMenuItemSelected(menuItem.path) ? "active" : ""
                  }`}
                >
                  <img
                    src={isMenuItemSelected(menuItem.path) ? menuItem.iconActive : menuItem.iconInactive}
                    alt={menuItem.name}
                  />
                  <span>{menuItem.name}</span>
                </NavLink>
              </li>
            ))}
          </ul>
          <ul className="logout">
            <li onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
              <NavLink to="/logout" className="menu-link">
                <img src="/images/assets/icons/logout.svg" alt="logout" />
                <span>Logout</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
