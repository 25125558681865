import PropTypes from "prop-types";
import React from "react";
import { Avatar } from "../Avatar";
import "./style.css";

export const Achievement = ({
  className,
  imageUrl = "https://cdn.iconscout.com/icon/free/png-256/free-medal-1007-591863.png",
  title,
  text,
  points,
}) => {
  return (
    <div className={`elements-achievement ${className}`}>
      <Avatar className="elements-achievement--avatar" imageUrl={imageUrl} component="avatar" type="default" />
      <div className="elements-achievement--content">
        {title && <div className="elements-achievement--content--title">{title}</div> }
        {text && <div className="elements-achievement--content--text">{text}</div>}
        {points && <div className="elements-achievement--content--points">{points} points</div>}
      </div>
    </div>
  );
};

Achievement.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  points: PropTypes.number,
};
