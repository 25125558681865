import React, { useContext, useEffect, useState } from "react";
import Prize from "../Prize/Prize";
import { toast } from "react-toastify";
import { getPrizes, redeemPrize } from "../../../services/gamelayer";
import { UserContext } from "../../../domain/UserProvider";
import "./styles.scss";

const ITEMS_PER_PAGE = 4;

const PrizesList = ({ usePagination, slimItems = true }) => {
  const [shopItems, setShopItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const { account } = useContext(UserContext);
  const glAccount = process.env.REACT_APP_ACCOUNT;

  useEffect(() => {
    const fetchShopItems = async () => {
      try {
        const itemsData = await getPrizes(glAccount);
        if (usePagination) {
          setTotalPages(Math.ceil(itemsData.length / ITEMS_PER_PAGE));
          const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
          const endIndex = startIndex + ITEMS_PER_PAGE;
          const itemsForPage = itemsData.slice(startIndex, endIndex);
          setShopItems(itemsForPage);
        } else {
          setShopItems(itemsData);
        }
      } catch (error) {
        console.error("Error fetching shop items:", error);
        toast.error(
          `Failed to retrieve shop items. Please try again. Error: ${error.message}`,
          {
            toastId: "shop-items-fetch-error",
          }
        );
      }
    };

    fetchShopItems();
  }, [account, currentPage, usePagination]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const showNotification = (message, type) => {
    toast[type](message);
  };

  const claimPrize = async (prizeId) => {
    try {
      const response = await redeemPrize(
        glAccount,
        account.player,
        prizeId
      );

      if (response.code === 1) {
        showNotification("Congrats! Item claimed!", "success");
        // Fetch the updated shop items after a successful prize claim
        const updatedItems = await getPrizes(glAccount);
        setShopItems(updatedItems);
      } else if (response.code === 2) {
        showNotification("Sorry. You don't have enough credits to claim this item.", "error");
      } else {
        showNotification("Sorry. An error occurred.", "error");
      }
    } catch (error) {
      console.error("Error claiming prize:", error);
      toast.error(
        `Failed to claim the prize. Please try again. Error: ${error.message}`,
        {
          toastId: "prize-claim-error",
        }
      );
    }
  };

  return (
    <div className="shop-items-container">
      {shopItems.length > 0 ? (
        shopItems.map((shopItem, index) =>
          shopItem.isAvailable ? (
            <Prize
              index={index}
              slim={slimItems}
              showButton={false}
              key={shopItem.id}
              shopItem={shopItem}
              claimPrize={claimPrize}
            />
          ) : (
            <span key={shopItem.id}></span>
          )
        )
      ) : (
        <div>No items added yet...</div>
      )}
      {totalPages > 1 && (
        <div className="pagination">
          <button
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
            className="arrow left"
          />
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              className={currentPage === index + 1 ? "active dot" : "dot"}
            >
            </button>
          ))}
          <button
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
            className="arrow right"
          />
        </div>
      )}
    </div>
  );
};

export default PrizesList;
