import React, { useEffect, useState } from "react";
import { MissionsList } from "../../components/Elements/MissionsList";
import "./style.scss";

const Events = () => {
  return (
    <div className="page-content events">
      <h1>Events</h1>
      <MissionsList></MissionsList>
    </div>
  );
};

export default Events;
