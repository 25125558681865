import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Avatar } from "../Avatar";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion"; // Import motion components
import "react-toastify/dist/ReactToastify.css";
import { getPlayers } from "../../../services/gamelayer.js";
import "./styles.scss";

const ITEMS_PER_PAGE = 8;

export const PlayersList = ({ usePagination, useSort, withNumber = false }) => {
  const [players, setPlayers] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSort, setCurrentSort] = useState("points");
  const glAccount = process.env.REACT_APP_ACCOUNT;

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const playersData = await getPlayers(glAccount, currentSort);

        if (usePagination) {
          setTotalPages(Math.ceil(playersData.length / ITEMS_PER_PAGE));
          const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
          const endIndex = startIndex + ITEMS_PER_PAGE;
          const playersForPage = playersData.slice(startIndex, endIndex);
          setPlayers(playersForPage);
        } else {
          // Set players with animation on sort change
          setPlayers([]);
          setTimeout(() => setPlayers(playersData), 0);
        }
      } catch (error) {
        console.error("Error fetching players:", error);
        toast.error(
          `Failed to retrieve players. Please try again. Error: ${error.message}`,
          {
            toastId: "players-fetch-error",
          }
        );
      }
    };

    fetchPlayers();
  }, [usePagination, currentPage, currentSort]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSortChange = async (currentSort) => {
    setCurrentSort(currentSort);
  };

  return (
    <>
      {useSort && (
        <div className="sort-buttons">
          <h3>Sort by:</h3>
          <div className="buttons">
            <motion.span
              onClick={() => handleSortChange("points")}
              className={currentSort === "points" ? "active" : ""}
              whileHover={{ scale: 1.1 }}
            >
              Points
            </motion.span>
            <motion.span
              onClick={() => handleSortChange("name")}
              className={currentSort === "name" ? "active" : ""}
              whileHover={{ scale: 1.1 }}
            >
              Name
            </motion.span>
            <motion.span
              onClick={() => handleSortChange("level")}
              className={currentSort === "level" ? "active" : ""}
              whileHover={{ scale: 1.1 }}
            >
              Level
            </motion.span>
            <motion.span
              onClick={() => handleSortChange("team")}
              className={currentSort === "team" ? "active" : ""}
              whileHover={{ scale: 1.1 }}
            >
              Team
            </motion.span>
          </div>
        </div>
      )}
      <div className="players-list">
        {players.map((player, index) => (
          <motion.div
            key={player.name}
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
            className="element-container"
          >
            <NavLink
              to={"/players/" + player.player}
            >
              {withNumber && currentSort !== "name" && currentSort !== "team" ? (
                <span className="position">{index + 1}</span>
              ) : (<span style={{width: "25px"}}> </span>)}
              <Avatar
                className="elements-user--avatar"
                imageUrl={player.imgUrl}
                levelIcon={player.level.imgUrl}
                component="avatar"
                type="default"
              />
              <h6>{player.name}</h6>
              {player.team ? (
                <p className="team">
                  Team: {player.team}
                </p>) : (<p className="team"></p>)
              }
              <p className="points">{player.points} points</p>
            </NavLink>
          </motion.div>
        ))}
      </div>
      {usePagination && totalPages > 1 && (
        <div className="pagination">
          <motion.button
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
            className="arrow left"
            whileHover={{ scale: 1.1 }}
          />
          {[...Array(totalPages)].map((_, index) => (
            <motion.button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              className={currentPage === index + 1 ? "active dot" : "dot"}
              whileHover={{ scale: 1.1 }}
            />
          ))}
          <motion.button
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
            className="arrow right"
            whileHover={{ scale: 1.1 }}
          />
        </div>
      )}
    </>
  );
};
