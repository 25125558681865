import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getPlayer } from "../../services/gamelayer";
import { useAuth0 } from "@auth0/auth0-react";
import "./styles.scss";

export const PlayerProfileEdit = () => {
  const [formData, setFormData] = useState({
    name: '',
    image: '',
    email: ''
  });
  const { playerId } = useParams();
  const { user } = useAuth0();
  const glAccount = process.env.REACT_APP_ACCOUNT;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [playerData] = await Promise.all([
          getPlayer(glAccount, playerId),
        ]);
        setFormData({
          name: playerData.name,
          image: playerData.imgUrl,
          email: playerData.player
        });
      } catch (error) {
        console.error("Error fetching player data:", error);
        toast.error(
          `Failed to retrieve player data. Please try again. Error: ${error.message}`,
          {
            toastId: "player-data-fetch-error",
          }
        );
      }
    };

    fetchData();
  }, [glAccount, playerId]);

  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  
  if (user.email.toLowerCase().trim() !== playerId.toLowerCase().trim()) {
    return (
      <h2>
        Editing other players not allowed, sorry.
      </h2>
    );
  }

  return (
    <section className="player-edit-page">
      <h1>Hello {user.name}!</h1>
      <div>here you can edit your account</div>
      <form className="player-edit-form">
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="image">Image URL:</label>
          {formData.image && (
            <div className="image-preview">
              <img src={formData.image} alt="Selected" />
            </div>
          )}
          <input
            type="url" 
            id="image"
            name="image"
            onChange={handleChange}
            value={formData.image}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            disabled
            className="form-control"
          />
        </div>
      </form>
    </section>
  );
};
