import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { ToastContainer } from "react-toastify";
import { Dashboard } from "./pages/Dashboard";
import Events from "./pages/EventsExt/EventsExt.js";
import MissionPage from "./pages/MissionPage/MissionPage";
import PrizePage from "./pages/PrizePage/PrizePage.jsx";
import { Challenges } from "./pages/Challenges/Challenges.jsx";
import Rankings from "./pages/Rankings/Rankings.jsx";
import Shop from "./pages/Shop/Shop.jsx";
import Prizes from "./pages/PrizesExt.js";
import { Sidebar } from "./components/Sidebar";
import Header from "./components/Header/Header.js";
import { Footer } from "./components/Footer";
import { UserProvider } from "./domain/UserProvider";
import { UserProfile } from "./domain/UserProfile.js";
import { PlayerProfile } from "./pages/PlayerProfile/PlayerProfile.jsx";
import { PlayerProfileEdit } from "./pages/PlayerProfileEdit/index.js";
import { AnimatePresence } from "framer-motion";
import { useLocation } from "react-router-dom"

import "./styles/app.scss";

function App() {
  const { REACT_APP_DOMAIN, REACT_APP_CLIENT_ID, REACT_APP_REDIRECT_URI } = process.env;

  const LocationProvider = ({ children }) => { return <AnimatePresence>{children}</AnimatePresence>; }

  const RoutesWithAnimation = () => {
    const location = useLocation();

    return (
      <Routes location={location} key={location.key}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/events" element={<Events />} />
        <Route path="/prizes" element={<Prizes />} />
        <Route path="/challenges" element={<Challenges />} />
        <Route path="/rankings" element={<Rankings />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/missions/:missionId" element={<MissionPage />} />
        <Route path="/prizes/:prizeId" element={<PrizePage />} />
        <Route path="/players/:playerId" element={<PlayerProfile />} />
        <Route path="/players/:playerId/edit" element={<PlayerProfileEdit />} />
      </Routes>
    );
  }

  return (
    <Auth0Provider
      domain={REACT_APP_DOMAIN}
      clientId={REACT_APP_CLIENT_ID}
      redirectUri={window.location.origin}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      <UserProfile>
        <UserProvider>
          <Header />
          <div className="app">
            <div className="main-container">
              <div className="content-container">
                <div className="page-container">
                  <Router>
                    <Sidebar />
                    <ToastContainer />
                    <LocationProvider>
                      <RoutesWithAnimation />
                    </LocationProvider>
                  </Router>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </UserProvider>
      </UserProfile>
    </Auth0Provider>
  );
}

export default App;
