import React, { useEffect, useState } from "react";

const RemainingTime = ({ endDate }) => {
  const calculateRemainingTime = (endDate) => {
    const now = new Date();
    const end = new Date(endDate);

    const diff = end - now;
    if (diff <= 0) return "Expired"; // Handle expired time

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    let remaining = "";
    if (days > 0) remaining += `${days}D `;
    if (hours > 0) remaining += `${hours}H `;
    if (minutes > 0) remaining += `${minutes}M`;

    return remaining.trim() || "Less than a minute";
  };

  const [remainingTime, setRemainingTime] = useState(calculateRemainingTime(endDate));

  useEffect(() => {
    let intervalId;
    if (endDate) {
      intervalId = setInterval(() => {
        const remaining = calculateRemainingTime(endDate);
        setRemainingTime(remaining);
      }, 1000);
    } else {
      setRemainingTime("End date not provided");
    }

    return () => clearInterval(intervalId); // Cleanup interval
  }, [endDate]);

  return (
    <div>
      Remaining time: {remainingTime}
    </div>
  );
};

export default RemainingTime;
