import React, { useEffect, useState } from "react";
import "./styles.scss";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { Tag } from "../Tag";

const Prize = ({
  index = 1,
  slim = true,
  showButton = false,
  shopItem,
  claimPrize,
  isAvailable = false,
  isLink = true
}) => {
  const [prizePageUri, setPrizePageUri] = useState();
  useEffect(() => {
    const prepareData = () => {
      setPrizePageUri("/prizes/" + shopItem.id);
    }

    prepareData();
  }, []);

  return (shopItem.isAvailable || isAvailable) && (
    <motion.div
      className="shop-item-wrapper"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: 0.5, delay: index * 0.2 }}
    >
      <div className="shop-item-container">
        <NavLink to={isLink ? prizePageUri : window.location.href}>
          <div className="shop-item--media">
            <img
              src={shopItem.imgUrl}
              alt={shopItem.name}
              className="shop-item-image"
            />
          </div>
          <h2>{shopItem.name}</h2>
          {slim ? (
            <div className="shop-item-info">
              <p>
                {shopItem.description === ""
                  ? " "
                  : shopItem.description.substring(0, 50)}...
              </p>
            </div>
          ) : (
            <div className="shop-item-info">
              <p>
                {shopItem.description === ""
                  ? "Description not set"
                  : shopItem.description}
              </p>
              { shopItem.stock && (
                <p>Redeemed: {shopItem.stock.redeemed}</p>
              )}
            </div>
          )}
          <div className="shop-item-info-bottom">
            {showButton ? (
              <button
                className="shop-item-redeem-btn"
                onClick={() => claimPrize(shopItem.id)}
              >
                <span>{shopItem.credits} Points</span>
              </button>
            ) : (
               shopItem.credits && (
                <Tag text={shopItem.credits + " Points"} inverted={true}/>
              )
            )}
            { shopItem.stock && (
              <p>Available: {shopItem.stock.available < 10000 ? shopItem.stock.available : 'plenty'}</p>
            )}
          </div>
        </NavLink>
      </div>
    </motion.div>
  )
};

export default Prize;
