import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import { getPlayerMission } from "../../../services/gamelayer.js";
import { UserContext } from "../../../domain/UserProvider";
import { motion } from "framer-motion"; // Import motion components
import RemainingTime from "./RemainingTime.jsx";
import { Tag } from "../Tag";
import "./styles.scss";

const Mission = ({
  missionId,
  imageUrl,
  title = "Title",
  text = "",
  slim = false,
  points,
  category,
  index = 1
}) => {
  const glAccount = process.env.REACT_APP_ACCOUNT;
  const { account } = useContext(UserContext);
  const [missionData, setMissionData] = useState({
    started: false,
    completed: false,
    progress: 0,
    ttc: null,
  });

  useEffect(() => {
    const fetchPlayerMissionData = async () => {
      try {
        const playerMissionData = await getPlayerMission(
          glAccount,
          account.player,
          missionId
        );
        setMissionData({
          available: playerMissionData.progress.state === 'available' || playerMissionData.progress.state === 'started', 
          started: playerMissionData.progress.state === 'started',
          completed: playerMissionData.progress.state === 'completed',
          progress: playerMissionData.progress.totalAverage,
          ttc: playerMissionData.progress.nextRefresh
        });
      } catch (error) {
        console.error(`Error fetching mission data for mission ID ${missionId}:`, error);
      }
    };

    fetchPlayerMissionData();
  }, [glAccount, account.player, missionId]);

  const { started, progress, ttc } = missionData;
  const missionUri = `/missions/${missionId}`;
  const showRemainingTime = ttc !== null;

  if (missionData.available === false) {
    return false;
  }
  return (
    <motion.div
      key={index}
      className="element-container"
      initial={{ opacity: 0, y: -60 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -60 }}
      transition={{ duration: 0.5, delay: index * 0.2 }}
      >
        <div className="elements-mission">
      <NavLink to={missionUri}
      >
          <div
            className="elements-mission--media"
            style={{ backgroundImage: `url(${imageUrl})` }}
          ></div>
          <div className="elements-mission--content">
            {category && (
              <div className="elements-mission--content--category">
                <Tag text={category} />
                {points && points > 0 && <Tag text={`${points} points`} inverted={true} />}
              </div>
            )}
            <div className="elements-mission--content--text">
              <div className="elements-mission--content--text--title">{title}</div>
              <p className="elements-mission--content--text--text">
                {slim && text.length > 120 ? `${text.substring(0, 120)}...` : text}
              </p>
            </div>
            <div className="elements-mission--info">
              <div className="elements-mission--info--progress">
                {!started ? (
                  <div>Not started yet</div>
                ) : progress < 1 ? (
                  <div>
                    <progress value={progress} max={1}></progress>
                  </div>
                ) : (
                  <div>Completed</div>
                )}
              </div>
              <div className="elements-mission--info--time">
                {started && showRemainingTime && <RemainingTime endDate={ttc} />}
              </div>
            </div>
          </div>
      </NavLink>
        </div>
    </motion.div>
  );
};

export default Mission;
