import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getMission, completeEvent } from "../../services/gamelayer";
import { Event } from "../../components/Elements/Event";
import { Tag } from "../../components/Elements/Tag";
import { motion } from "framer-motion";
import "./styles.scss";

const MissionsPage = () => {
  const { missionId } = useParams();
  const glAccount = process.env.REACT_APP_ACCOUNT;
  const [mission, setMission] = useState(null);

  const routeVariants = {
    initial: {
      opacity: 0,
      y: "50px",
    },
    final: {
      opacity: 1,
      y: "0px",
      transition: {
        duration: 0.3,
        delay: 0.2,
      },
    },
  }

  useEffect(() => {
    if (missionId) {
      const fetchMission = async () => {
        try {
          const missionData = await fetchMissionData();
          setMission(missionData);
        } catch (error) {
          console.error("Error fetching missions:", error);
          toast.error(
            `Failed to retrieve missions. Please try again. Error: ${error.message}`
          );
        }
      };

      fetchMission();
    }
  }, [missionId]);

  const fetchMissionData = async () => {
    const missionData = await getMission(glAccount, missionId);
    return missionData;
  };

  return (
    <>
      {mission && (
        <motion.div
          variants={routeVariants} 
          className="mission--page">
          <NavLink
            to="/challenges"
            className="back-link"
            >
            <img src="/images/assets/arrow_left.svg" alt="back"></img>
            Challenges
          </NavLink>
          <img className="mission--image" alt="" src={mission.imgUrl}></img>
          <div className="tags">
            {mission.category && (
              <Tag text={mission.category} />
            )
            }
            {
              mission.tags.length > 0 && (
                <>
                  { mission.tags.map((tag, index) => (
                    <Tag key={index} text={tag} />
                  ))}
                </>
              )
            }
          </div>
          <div className="mission--content">
            <h1>{mission.name}</h1>
            <p>
              {mission.description}
            </p>
          </div>
          <div className="mission--buttons">
            {mission.objectives.events.map((event, index) => (
              <Event
                key={index}
                id={event.id}
              >
              </Event>
            )
            )}
          </div>
        </motion.div>
      )}
    </>
  );
};

const handleMissionCompletion = async (missionId) => {
  try {
    const response = await completeEvent(missionId);
    if (response === 200) {
      toast.success("Mission completed successfully!");
    } else {
      toast.error("Failed to complete the mission. Please try again later.");
    }
  } catch (error) {
    console.error("Error completing the mission:", error);
    toast.error(
      `Failed to complete the mission. Please try again. Error: ${error.message}`
    );
  }
};

export default MissionsPage;
