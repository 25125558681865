import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Logo2 } from '../icons/Logo2';
import './styles.scss';

function LoginButton() {
  const { loginWithRedirect } = useAuth0();

  return <button className='login-buuton' onClick={() => loginWithRedirect()}>Log In</button>;
}

function LogoutButton() {
  const { logout } = useAuth0();

  return <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>Log Out</button>;
}

export const UserProfile = ({ children }) => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div>
        <Logo2></Logo2>
        <div>Loading...</div>
      </div>
    );
  }

  return (
    <div>
      {isAuthenticated ? (
        <section>
          { children }
        </section>
      ) : (
        <section className='login-screen'>
          <div>
            <h1>
              <Logo2 /> <span> GameLayer</span>
            </h1>
            <h3>
               Welcome to GameLayer that rewards your physical activity with donations to good causes and sustainable initiatives
            </h3>
            <LoginButton />
          </div>
        </section>
      )}
    </div>
  );
}
