import React from "react";
import "./style.scss";

export const Tag = ({ text = "Tag!", inverted=false}) => {
  return (
    <div className={ inverted ? "elements--tag inverted" : "elements--tag"}>
      <div className="elements--tag--text-wrapper">{text}</div>
    </div>
  );
};
